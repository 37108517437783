import React, { useEffect, useState } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Banner from "../Common/Banner/Banner";
import Breadcrumb from "../Common/Breadcrumb/Breadcrumb";
import SideNavbar from "../Templates/SideNavbar/OurQualitySideNavbar";
import LoadingOverlay from "react-loading-overlay";
import { getData } from "../../Utils/utils";

const OurQualityComplaintPolicy = () => {
  const [isLoading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const fetchTeamsData = async () => {
    setLoading(true);
    const { complaintPolicy = "" } = await getData("/ourquality/get");
    setContent(complaintPolicy);
    setLoading(false);
  };

  useEffect(() => {
    fetchTeamsData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var emailList = [{ email: "Info@RMRMs.com" }, { email: "" }, { email: "" }];

  LoadingOverlay.propTypes = undefined;

  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <Header />

        <div className="mainSection">
          <Banner
            bgPath={"url(/images/HeaderDark.jpg)"}
            breadcrumb={
              <Breadcrumb
                title="Our Quality Complaint Policy"
                listItem="Home"
                listItemPath="/"
                listLink="Our Quality Complaint Policy"
                listLinkPath="/ourQualityComplaintPolicy"
              />
            }
          />

          <div className="container">
            <div className="row">
              <div className="col_sm_12 col_md_5 col_lg_3">
                <SideNavbar />
              </div>

              <div className="col_sm_12 col_md_7 col_lg_9">
                <section className="page__section pb_0">
                  <div
                    dangerouslySetInnerHTML={{ __html: content }}
                    // className="reset-props"
                  ></div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <Footer footerEmails={true} emailList={emailList} />
      </LoadingOverlay>
    </>
  );
};

export default OurQualityComplaintPolicy;
