import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import Header from "../components/Header";
import DrawerCom from "../components/Drawer";
import { getData, postData } from "../../Utils/utils";

import LoadingOverlay from "react-loading-overlay";
import TextEditor from "../components/Editor";

const mdTheme = createTheme();

function DashboardContent() {
  const [pageStore, setPageStore] = useState({
      certificates: "",
      policy: "",
      complaintPolicy: "",
      home: "",
    }),
    [open, setOpen] = useState(true),
    [isError, setError] = useState(false),
    [isLoading, setLoading] = React.useState(false);

  const fetchProduct = async () => {
    const {
      certificates = "",
      policy = "",
      complaintPolicy = "",
      home = "",
    } = await getData("/ourquality/get");
    setPageStore({
      certificates,
      policy,
      complaintPolicy,
      home,
    });
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  const onPageChange = async (e, name, index) => {
      if (name === "complaintPolicy") {
        setPageStore({ ...pageStore, [name]: e });
      }
    },
    toggleDrawer = () => {
      setOpen(!open);
    },
    handleSubmit = async (event) => {
      event.preventDefault();
      if (!pageStore.complaintPolicy) {
        setError(true);
        return;
      }

      const obj = {
        id: 1,
        home: pageStore.home,

        certificates: pageStore.certificates,
        policy: pageStore.policy,
        complaintPolicy: pageStore.complaintPolicy,
      };

      setLoading(true);
      await postData(obj, "/ourquality/add");
      setLoading(false);
    };
  LoadingOverlay.propTypes = undefined;

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Header open={open} toggleDrawer={toggleDrawer} />
          <DrawerCom open={open} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      minHeight: 240,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ pb: 2 }}
                    >
                      Our Quality Complaint Policy
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid
                        container
                        spacing={3}
                        sx={{ width: "100%", marginTop: "1px" }}
                      >
                        <Grid item xs={12}>
                          <TextEditor
                            editorValue={pageStore.complaintPolicy}
                            onChange={(newContent) =>
                              onPageChange(newContent, "complaintPolicy")
                            }
                          />
                          {isError && (
                            <p style={{ color: "red" }}>Please enter Content</p>
                          )}
                        </Grid>
                      </Grid>

                      <Button
                        type="submit"
                        className={"btnClass"}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </LoadingOverlay>
  );
}

export default function ourQualityComplaintPolicy() {
  return <DashboardContent />;
}
