import React, { useRef } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Inventory2TwoToneIcon from "@mui/icons-material/Inventory2TwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import FactoryTwoToneIcon from "@mui/icons-material/FactoryTwoTone";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import CardMembershipRoundedIcon from "@mui/icons-material/CardMembershipRounded";
import DesignServicesTwoToneIcon from "@mui/icons-material/DeselectTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";

export const menu = [
  {
    icon: <HomeTwoToneIcon />,
    title: "Home",
    to: "/admin/home",
  },
  {
    icon: <Inventory2TwoToneIcon />,
    title: "Product",
    items: [
      {
        title: "Home",
        to: "/admin/productHome",
      },
      {
        title: "Category",
        to: "/admin/createCategory",
      },
      {
        title: "Products",
        to: "/admin/listing",
      },
      // {
      //     title: "Product Page",
      //     to:"/admin/subProduct"
      // },
      //    {
      //         title: "Ausmon Monitors",
      //         to:"/admin/ausmonMonitors"
      //     }
    ],
  },
  {
    icon: <DesignServicesTwoToneIcon />,
    title: "Services",
    items: [
      {
        title: "Home",
        to: "/admin/serviceHome",
      },
      {
        title: "Category",
        to: "/admin/serviceListing",
      },
    ],
  },
  {
    icon: <CardMembershipRoundedIcon />,
    title: "Certificate",
    to: "/admin/certificateListing",
  },

  {
    icon: <FactoryTwoToneIcon />,
    title: "Industry Catalogs",
    to: "/admin/catalogListing",
  },
  /*    {
            icon: <PageviewTwoToneIcon />,
            title: "Search Engine",
            to:"/admin/searchEngine",
        },*/
  {
    icon: <InfoTwoToneIcon />,
    title: "Our Quality",
    items: [
      {
        title: "Home",
        to: "/admin/ourQualityHome",
      },
      {
        title: "Certificates",
        to: "/admin/ourQualityCertificates",
      },
      {
        title: "Policy",
        to: "/admin/ourQualityPolicy",
      },
      {
        title: "Complaint Policy",
        to: "/admin/ourQualityComplaintPolicy",
      },
    ],
  },
  {
    icon: <InfoTwoToneIcon />,
    title: "About Us",
    items: [
      {
        title: "Home",
        to: "/admin/about",
      },
      {
        title: "Latest News",
        to: "/admin/latestNewsListing",
      },
      {
        title: "Our Team",
        to: "/admin/ourTeam",
      },

      {
        title: "Photo Gallery",
        to: "/admin/photoGallery",
      },
    ],
  },
  {
    icon: <InfoTwoToneIcon />,
    title: "Customer Portal",
    items: [
      {
        title: "RMPT Status",
        to: "/admin/formStatusAdmin",
      },
      {
        title: "Inventory Listing",
        to: "/admin/inventoryListing",
      },
      {
        // icon: <CardMembershipRoundedIcon />,
        title: "Users",
        to: "/admin/userListing",
      },
      {
        // icon: <CardMembershipRoundedIcon />,
        title: "Company",
        to: "/admin/companyListing",
      },
    ],
  },
];

const hasChildren = (item) => {
  const { items: children } = item;
  if (children === undefined) {
    return false;
  }
  if (children.constructor !== Array) {
    return false;
  }
  if (children.length === 0) {
    return false;
  }
  return true;
};

function MainListItems() {
  return menu.map((item, key) => <MenuItem key={key} item={item} />);
}

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
  return (
    <ListItem
      component={Link}
      to={item.to}
      selected={item.to === window.location.pathname}
      style={{ color: "black" }}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  //   const [open, setOpen] = useState(true);

  //   const handleClick = () => {
  //     setOpen((prev) => !prev);
  //   };

  const collapseHandler = useRef();
  const currentCollapseItem = useRef();

  return (
    <React.Fragment>
      <ListItem
        button
        onClick={() => {
          collapseHandler.current.classList.toggle("collapse-active");
          currentCollapseItem.current.classList.toggle("current-collapse-item");
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        <ExpandMore ref={currentCollapseItem} />
      </ListItem>
      <Collapse
        in={true}
        timeout="auto"
        unmountOnExit
        ref={collapseHandler}
        className="muiCollapse"
      >
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const SecondaryListItems = () => {
  const logout = () => {
    // window.localStorage.clear();
    localStorage.removeItem("access_token");
    localStorage.removeItem("name");
    window.location.reload();
  };
  return (
    <React.Fragment>
      <ListItemButton>
        <ListItemIcon>
          <ExitToAppTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="LogOut" onClick={logout} sx={{ ml: -2 }} />
      </ListItemButton>
    </React.Fragment>
  );
};

export { MainListItems, SecondaryListItems };
