import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { getData } from "../../../Utils/utils";

import "./sideNavbar.scss";
import ArrowDown from "../../Icon/ArrowDown";

const SideNavbar = () => {
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState({}),
    { aboutUs: aboutUsPage = {}, latestNews = [] } = product || {},
    fetchProductsData = async () => {
      const result = await getData("/get/contents/by/category");
      setProduct(result);
    };

  useEffect(() => {
    fetchProductsData();
  }, []);

  return (
    <div className="accordion accordion-sm">
      <div className="accordion__item">
        <NavLink
          component={Link}
          state={{ content: aboutUsPage }}
          to={"/about"}
          className="accordion__item--btn"
        >
          Home
        </NavLink>
      </div>
      <div className="accordion__item">
        <NavLink to={"/photo-gallery"} className="accordion__item--btn">
          Photo Gallery
        </NavLink>
      </div>
      <div className="accordion__item">
        <NavLink
          component={Link}
          state={{ content: latestNews }}
          to={"/latest-news"}
          className="accordion__item--btn"
        >
          Latest News
        </NavLink>
      </div>
      <div className="accordion__item">
        <NavLink to={"/contact"} className="accordion__item--btn">
          Contact Us
        </NavLink>
      </div>
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className="accordion__item"
      >
        <button className="accordion__item--btn">
          Our Quality
          <ArrowDown />
        </button>
      </div>
      {open ? (
        <div className="accordion__item">
          <ul>
            <li>
              <NavLink className="accordion__item--btn" to="/ourQuality">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                className="accordion__item--btn"
                to="/ourQualityCertificates"
              >
                Certificates
              </NavLink>
            </li>
            <li>
              <NavLink className="accordion__item--btn" to="/ourQualityPolicy">
                Policy
              </NavLink>
            </li>
            <li>
              <NavLink
                className="accordion__item--btn"
                to="/ourQualityComplaintPolicy"
              >
                Complaint Policy
              </NavLink>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default SideNavbar;
